<template>
    <div class="page casino">
        <div class="header">
			<v-container>
                <v-row>
                    <v-col cols='6'>
                        <div class="entity" v-if="data" data-aos="fade">
                            <div class="title">Casino {{data.name}}</div>
                            <div class="subtitle">Technical Perspective</div>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="stats_container" v-if="statsData" data-aos="fade">
                    <v-col cols="12">
                        <v-slide-group show-arrows>
                            <v-slide-item v-for='(item,index) in cardInfo' :key='index'>
                                <v-card class="nav_tech_stats">
                                    <p>{{item.label}}</p>
                                    <div v-if='item.type == "col"'>
                                        <span class='value'>{{item.value[0]}}</span>	 
                                        <span class='value float-right'>{{item.value[1]}}</span>
                                    </div>
                                    <div v-if='!item.type'>
                                        <span class='value'>{{item.value}}</span>	 
                                    </div>
                                    <div v-if='item.type == "col"'>
                                        <span class='label float-left'>Connected</span>
                                        <span class='label float-right'>Total</span>
                                    </div>
                                    <div v-if='!item.type'>
                                        <span class='label float-left'>Connected</span>
                                    </div>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </v-col>
                </v-row>
			</v-container>
		</div>
        <div>
            <v-container>
                <v-row>
                    <v-col xs='12' sm='12' md='4' lg='4' xl='4' class="side_bar">
                        <div class="navigation navigation-blue">
                            <tab-switch :switchNames="switchNames" @changeActive='changeActiveSideTab'></tab-switch>
                        </div>
                        
                        <div class="card card-blue" v-if="switchNames[0].value" data-aos="fade" style='margin-top:20px'>                            
                            <div class="card_header sys_info">
                                <span>Main Event Log</span>
                                
                            </div>
                            <div class="table_container">
                               
                            </div>
                        </div>
                        <div class="card card-blue" v-if="switchNames[1].value" data-aos="fade" style='margin-top:20px'>                            
                            <div class="card_header sys_info">
                                <span>Statistics</span>
                                
                            </div>
                            <div class="table_container">
                               
                            </div>
                        </div>
                    </v-col>

                    <v-col xs='12' sm='12' md='8' lg='8' xl='8'>
                        <div class="navigation navigation-teal">
                            <tab-nav :tabNames="tabNames"></tab-nav>
                        </div>
                        <div class="content" v-if="data">
                            <router-view :casino="data" v-if='zoneTypes' :zoneTypes='zoneTypes'></router-view>     
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
import TabNav from '@/components/navigation/TabNav.vue'
import TabSwitch from '@/components/navigation/TabSwitch.vue'
import CrudTable from '@/components/tables/CrudTable.vue'


export default {
    components:{
        'tab-nav':TabNav,
        'tab-switch':TabSwitch,
        'crud-table':CrudTable
    },
    data(){
        return {
            data:null,
            zoneTypes:null,
            statsData:null,
            tabNames: [
                {
                    label:null,
                    route:'physical'   
                },
                {
                    label:null,
                    route:'virtual'   
                },
                {
                    label:null,
                    route:'play'
                }
            ],
            switchNames: [
                {
                    label:'Main Event Log',
                    value: true
                },
                {
                    label:'Statistics',
                    value: false
                }
            ],
        }
    },
    computed:{
        cardInfo: function(){
            return [
            {
                label:'Zones',
                value: [this.statsData.zones.active, this.statsData.zones.total],
                type: 'col'
            },
            {
                label:'EGMs',
                value: [this.statsData.egms.active, this.statsData.egms.total],
                type: 'col'
            },
            {
                label:'Streamer',
                value: [this.statsData.allStreams.online, this.statsData.allStreams.total],
                type: 'col'
            },
            {
                label: 'RIB',
                value: [this.statsData.controlBoards.online, this.statsData.controlBoards.total],
                type: 'col'
            },
            {
                label: 'Service',
                value:  this.statsData.service.total,
                type:  null
            },
            {
                label: 'JP Streamer',
                value: [this.statsData.jpStreams.online, this.statsData.jpStreams.total],
                type:  'col'
            },
            {
                label: 'Surv Streamer',
                value: [this.statsData.survStreams.online, this.statsData.survStreams.total],
                type:  'col'
            },
        ]
        }
    },
    mounted(){
        this.getCasino();
        this.getZoneTypes();
        this.getCasinoStats();
    },
    sockets: {
        update_broadcasters: function() {
            this.getCasinoStats();
            this.getZoneTypes();
        }
    },
    methods: {
        getCasino(){
            axios.get('system/casinos/'+ this.$route.params.casino_id)
                .then(res=>{
                    this.data = res.data;             
                });
        },
        getCasinoStats(){
            axios.get('https://signal-ct.cloud.ro/api/casinoStats')
                .then(res=>{
                    this.statsData = res.data;             
                });
        },
        getZoneTypes(){
            axios.get('casinoZoneTypes')
                .then(res=>{
                    this.zoneTypes = res.data;
                    this.zoneTypes.forEach(el=>{
                        this.tabNames.forEach(item=>{
                            if(el.name.toLowerCase() === item.route){
                                item.label = el.name;
                            }
                        });
                    });
                });
        },
        changeActiveSideTab(){
            this.switchNames.forEach(el=>{
                el.value = !el.value;
            });
        }

    }
}
</script>