<template>
<div class="list casino">
	<div class="header">
		<v-container>
			<div class="entity" data-aos="fade">
				<div class="title">Casinos</div>
				<div class="subtitle">Index</div>
			</div>
		</v-container>
	</div>
	<div class="content"><br>
		<v-container v-if="payload" data-aos="fade">
			<v-row>
				<v-col md="2" v-for="casino in payload" :key="casino.id">
					<router-link :to="'/panel/technical/casino/' + casino.id" class="dark">
						<div class="card card-blue" style="height: 100%" v-ripple>
							<v-row>

								<v-col cols="6">

									<div class="image" v-if="!casino.logoMediaFile">
										<v-img src="/img/images_placeholder2x.png" />
									</div>

									<div class="image" v-if="casino.logoMediaFile">
										<v-img :src="getImage(casino.logoMediaFile.fileName )" alt="" />
									</div>
									
								</v-col>

								<v-col cols="6">
									<div class="title">
										<strong>
											{{ casino.name }}
										</strong>
									</div>

									<v-chip color="green" >{{ casino.status }}</v-chip>
									

									<div class="count">
										{{ casino.numberOfMachines}}
										
									</div>
									<div class="connected">Connected</div>
									<div class="id">
										<small>
											{{ casino.distributedId }}
										</small>
									</div>
								</v-col>
							</v-row>
							
						</div>
					</router-link>
				</v-col>
			</v-row>
		</v-container>
	</div>
</div>
</template>
<style scoped lang="scss">
	.image {
		border-radius: 6px;
		border: 1px solid #c1c1c1;
	}
	.count {
		font-size: 32px;
		font-weight: 900;
		color: #000 !important;

		
	}

	.connected {
			font-weight: 300px !important;
			font-size: 18px;
			color: #c1c1c1;
			margin-top: -10px;
		}

	.v-chip {
		pointer-events: none;
		color: #fff;
		padding: 0px 7px;
		height: 25px;
		border-radius: 6px;
	}



</style>
<script>
export default {

	data(){
		return {
			payload: null,
		}
	},
	mounted() {
		this.getCasinos();
	},
	methods: {
		getCasinos() {
			axios.get('/backend/casinos')
				 .then(res => {
				 	this.payload = res.data;
				 })
				 .catch(err => {
				 	Vue.toasted.error('There was a problem getting the data');
				 });
		}
	}

}
</script>