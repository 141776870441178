<template>
    <div class="tab devices" style="margin-top: 20px;">
        <v-row>
            <v-col col="12">
                <div class="card card-teal" v-if='egmData' data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Devices</span>
                        <span></span>
                    </div>
                    <crud-table class="gen_table"  :data='egmData.devices' :fields='fields'></crud-table>
                </div>
            </v-col>
        </v-row>                     
    </div>
</template>
<script>
import CrudTable from '@/components/tables/CrudTable.vue';
export default {
    components: {
        'crud-table':CrudTable,
    },
    props:['egmData'],
    data(){
        return { 
            fields:[
                {
                    label:'Serial No',
                    getValue: item => item.serial,
                    getLink: item => '/panel/technical/device/'+item.id,
                    type:'text',
                    key:'serial'
                },
                {
                    label: 'Manufacturer',
                    getValue: item => item.manufacturerId,
                    type: 'text',
                    key: 'manufacturer'
                },

                {
                    label: 'Model',
                    getValue: item => item.type.name, 
                    type: 'text',
                    key: 'model'
                },

                {
                    label: 'Version',
                    getValue: item => item.version,
                    type: 'text',
                    key: 'version',
                },

                {
                    label: 'IP',
                    getValue: item => item.ipAddress,
                    type:'text',
                    key:'ipAddress'
                },
                {
                    label: 'Port',
                    getValue: item => item.port,
                    type:'text',
                    key:'port'
                },
                {
                    label:'MAC',
                    getValue: item => item.macAddress,
                    type:'text',
                    key:'macAddress'
                },
                {
                    label:'Audit Interval',
                    getValue: item => (item.audit) ? item.audit : null,
                    type:'text',
                    key:'audit'
                },
                {
                    label:'Status Interval',
                    getValue: item => (item.statusInterval) ? statusInterval : null,
                    type:'text',
                    key:'statusInterval'
                },
                {
                    label:'Min Credit interval',
                    getValue: item => (item.minCredit) ? item.minCredit : null,
                    type:'text',
                    key:'minCredit'
                },
            ],
        }
    },
    
    mounted(){
       
    },
    methods: {
        
    }
}
</script>