<template>
    <div class="page zone">
        <div class="header-narrow">
			<v-container>
                <v-row>
                    <v-col cols='6'>
                        <div class="entity" v-if='manufacturerData' data-aos="fade">
                            <div class="title">{{manufacturerData.name}}c</div>
                            <div class="subtitle">Manufacturer</div>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="stats_container" v-if='statsData' data-aos="fade">
                    <v-col cols="12">
                        <v-slide-group show-arrows>
                            <v-slide-item v-for='(item,index) in cardInfo' :key='index'>
                                <v-card class="nav_tech_stats">
                                    <p>{{item.label}}</p>
                                    <div v-if='item.type == "col"'>
                                        <span class='value'>{{item.value[0]}}</span>	 
                                        <span class='value float-right'>{{item.value[1]}}</span>
                                    </div>
                                    <div v-if='!item.type'>
                                        <span class='value'>{{item.value}}</span>	 
                                    </div>
                                    <div v-if='item.type == "col"'>
                                        <span class='label float-left'>Connected</span>
                                        <span class='label float-right'>Total</span>
                                    </div>
                                    <div v-if='!item.type'>
                                        <span class='label float-left'>Connected</span>
                                    </div>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </v-col>
                </v-row>
			</v-container>
		</div>
        <div>
            <v-container>
                <v-row>
                    <v-col xs='12' sm='12' md='12' lg='12' xl='12'>
                        <div class="content">
                           <div class="tab egms">
                                <v-row>
                                    <v-col col="12">
                                        <div class="card card-teal" style='margin-top:60px' v-if='data' data-aos="fade">
                                            <div class="card_header">
                                                <span class="float-left">EGMs</span>
                                                <span></span>
                                            </div>
                                            <crud-table class="gen_table"  :data='data' :fields='fields'></crud-table>
                                        </div>
                                    </v-col>
                                </v-row>                     
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>

import CrudTable from '@/components/tables/CrudTable.vue'


export default {
    components:{
        'crud-table':CrudTable
    },
    data(){
        return {
            data:null,
            manufacturerData:null,
            statsData:null,
            fields:[
                {
                    label: 'Serie',
                    getValue: item => item.serial,
                    getLink: item => '/panel/technical/egm/' + item.id, 
                    type: 'text',
                    key: 'serial',
                },

                {
                    label: 'Manufacturer',
                    getValue: item => item.manufacturer.name,
                    type: 'text',
                    key: 'manufacturer'
                },

                {
                    label: 'Nr Aparat',
                    getValue: item => item.number, 
                    type: 'text',
                    key: 'number'
                },

                {
                    label:'Operator',
                    getValue: item => item.logisticStatus.name,
                    getClass: item=> (item.logisticStatus.name == 'Connected') ? 'isOn': 'isOff',
                    type:'text',
                    key:'logisticStatus'
                },
                {
                    label: 'Business',
                    getValue: item => (item.businessStatus.name==='LiveOnly') ? 'Live' : (item.businessStatus.name==='RemoteOnly') ? 'Remote' : 'Live&Remote',
                    type:'text',
                    key:'businessStatus'
                },
                {
                    label: 'Comm',
                    getValue: item => (item.commReady) ? 'Comm Ready' : 'Not available',
                    getClass: item=> (item.commReady === true) ? 'isOn': 'isOff',
                    type:'text',
                    key:'streamers'
                },
                {
                    label:'R2A',
                    getValue: item => (item.signalStatus==='active') ? 'Available' : (item.signalStatus==='play') ? 'Play' : 'Offline',
                    getClass: item => (item.signalStatus==='active') ? 'isOn' : (item.signalStatus==='play') ? 'inPlay' : 'isOff',
                    type:'text',
                    key:'r2a'
                },
                {
                    label:'RIB',
                    getValue: item => (item.controlBoard) ? item.controlBoard.signalTimestamp : 'Not available',
                    getClass: item => (item.controlBoard) ? ((item.controlBoard.signalStatus === 'online') ? 'isOn' : 'isOff') : 'isOff',
                    type:'date',
                    key:'rib'
                },
                {
                    label:'Game',
                    getValue: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.code === 'Game'); if(x[0]) {return x[0].signalTimestamp}else{return 'Not available'}},
                    getClass: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.code === 'Game'); if(x[0]) {return (x[0].signalStatus === "online") ? 'isOn' : 'isOff'}else{return 'isOff'}},
                    type:'date',
                    key:'game'
                },
                {
                    label:'Jackpot',
                    getValue: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.code === 'Jackpot'); if(x[0]) {return x[0].signalTimestamp}else{return 'Not available'}},
                    getClass: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.code === 'Jackpot'); if(x[0]) {return (x[0].signalStatus) ? ((x[0].signalStatus === "online") ? 'isOn' : 'isOff') : 'isOff'}else{return 'isOff'}},
                    type:'date',
                    key:'jackpot'
                },
                {
                    label:'Floor',
                    getValue: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.id === 2);  if(x[0]) {return x[0].signalTimestamp}else{return 'Not available'}},
                    getClass: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.id === 2);  if(x[0]) {return (x[0].signalStatus) ? ((x[0].signalStatus === "online") ? 'isOn' : 'isOff') : 'isOff'}else{return 'isOff'}},
                    type:'date',
                    key:'floor'
                },
            ],
        }
    },
    computed:{
        cardInfo: function(){
            return [
            {
                label:'EGMs',
                value: [this.statsData.egms.active, this.statsData.egms.total],
                type: 'col'
            },
            {
                label:'Streamer',
                value: [this.statsData.allStreams.online, this.statsData.allStreams.total],
                type: 'col'
            },
            {
                label: 'RIB',
                value: [this.statsData.controlBoards.online, this.statsData.controlBoards.total],
                type: 'col'
            },
            {
                label: 'Service',
                value:  this.statsData.service.total,
                type:  null
            },
            {
                label: 'JP Streamer',
                value: [this.statsData.jpStreams.online, this.statsData.jpStreams.total],
                type:  'col'
            },
            {
                label: 'Surv Streamer',
                value: [this.statsData.survStreams.online, this.statsData.survStreams.total],
                type:  'col'
            },
            {
                label: 'Games',
                value: [this.statsData.gameStreams.online, this.statsData.gameStreams.total],
                type:  'col'
            },
        ]
        }
    },
    mounted(){
        this.getManufacturer();
        this.getEgmsByManufacturer();
    },
    sockets: {
        update_broadcasters: function() {
            this.getEgmsByManufacturer();
        }
    },
    methods: {
        getManufacturer(){
            axios.get('egmManufacturers/'+this.$route.params.manufacturer_id)
                .then(res=>{
                    this.manufacturerData = res.data
                })
        },
        getEgmsByManufacturer(){
            axios.get('https://signal-ct.cloud.ro/api/manufacturers/'+this.$route.params.manufacturer_id)
                .then(res=>{
                    this.data= res.data
                })
        }
    }
}
</script>