<template>
<div class="list manufacturer">
	<div class="header">
		<v-container>
			<div class="entity" data-aos="fade">
				<div class="title">Manufacturers</div>
				<div class="subtitle">Index</div>
			</div>
		</v-container>
	</div>
	<div class="content"><br>
		<v-container v-if="payload" data-aos="fade">
			<v-row>
				<v-col md="2" v-for="manufacturer in payload" :key="manufacturer.id">
					<router-link :to="'/panel/technical/manufacturer/' + manufacturer.id" class="dark">
						<div class="card card-blue" v-ripple>
							<div class="title">
								<strong>
									{{ manufacturer.name }}
								</strong>
							</div>
							<div class="id">
								<small>
									{{ manufacturer.status }}
								</small>
							</div>
						</div>
					</router-link>
				</v-col>
			</v-row>
		</v-container>
	</div>
</div>
</template>
<script>
export default {

	data(){
		return {
			payload: null,
		}
	},
	mounted() {
		this.getManufacturers();
	},
	methods: {
		getManufacturers() {
			axios.get('egmManufacturers')
				 .then(res => {
				 	this.payload = res.data;
				 })
				 .catch(err => {
				 	Vue.toasted.error('There was a problem getting the data');
				 });
		}
	}

}
</script>