<template>
    <div class='page debug'> 
        <div class="header-narrow">
			<v-container>
                <v-row>
                    <v-col cols='6'>
                        <div class="entity" data-aos="fade">
                            <div class="title">Main Infrastructure</div>
                            <div class="subtitle">DEBUG VIEW: Main Communication</div>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="header_button_container">
                           
                            <v-btn :elevation="0" class="red_btn float-right" @click="clickRestart"><v-icon>mdi-sync</v-icon> Restart Server</v-btn>
                            <v-btn :elevation="0" class="print_btn float-right" :class='{"autoscroll-active": autoScroll}' @click='toggleAutoScroll'>Autoscroll</v-btn>
                            <v-text-field
                                v-model='text_filter'
                                success
                                outlined
                                dense
                                placeholder="Filter"
                                class='debug-input float-right'
                            ></v-text-field>
                            <v-select
                                v-model='level'
                                :items='items'
                                item-text='text'
                                item-value='value'
                                outlined
                                dense
                                success
                                class='debug-select float-right'
                            ></v-select>
                        </div>
                    </v-col>
                </v-row>
			</v-container>
		</div>

        <!-- Controls -->
        <div class="signal">       
            <div id="output" class="output"  >
                <div class="" >
                    <div :class='(message.status == "error") ? "errors": (message.status == "warning") ? "warnings" : "" ' class="message" v-for="(message,index) in filtered" :key='index' v-if="level >= message.level">
                        <v-row no-gutters>
                            <v-col cols='1'>
                                 <v-row no-gutters>
                                    <v-col cols="12" >
                                        <inline-svg :src='icons[message.symbol]'/>
                                        <span :style="{backgroundColor: direction[message.symbol]}" class='direction'>{{message.direction}}</span>
                                    </v-col>
                                    <v-col cols="12" >
                                        <div class='toggle-json'>
                                            <inline-svg 
                                            @click='toggleJson' 
                                            src='/svg/json.svg'
                                            :fill='(message.status == "error") ? "#F64637": (message.status == "warning") ? "#FF9100" : "#C1C1C1"'
                                            />
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="9">
                                <div class="timestamp">{{ message.timestamp | moment('YYYY-MM-DD HH:mm:ss')}}</div>
                                <div v-if='!json_view' class='content'>{{message.message}}</div>
                                <json-viewer 
                                    v-if='json_view'
                                    :value='message.message'
                                    :expand-depth='5' 
                                ></json-viewer>                                
                            </v-col>
                            <v-col cols="2" class='details-col'>
                                <div class="details">MAC: <span>{{message.details.mac}}</span></div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import JsonViewer from 'vue-json-viewer';
import InlineSvg from 'vue-inline-svg';
export default {
    components:{
        'json-viewer' : JsonViewer,
        'inline-svg' : InlineSvg
    },
    data() {
        return {
            debug: false, 
            force_turn: false,

            json_view:false,

  	        video_div: null,

            autoScroll: true,

            text_filter: null,

            level: 2,

            messages: [],
            items: [
                {
                    text: 'All Communication',
                    value: 2
                },
                {
                    text: 'Less Communication',
                    value: 1
                }
            ],
            icons: {
                bell: '/svg/bell.svg',
                warning: '/svg/alert.svg',
                session: '/svg/clock.svg',
                money: '/svg/credits.svg',
                slot: '/svg/slot.svg',
                person: '/svg/user.svg',
                error: '/svg/warning.svg'
            },
            direction: {
                bell: '#00B9D6',
                warning: '#FF9100',
                session: '#E600FF',
                money: '#00AC3B',
                slot: '#1D90E0',
                person: '#892EFF',
                error: '#F64637'
            },

        }
    },
    computed: {

    filtered: function() {

        // No active text filter
        if(!this.text_filter) {
            return this.messages;
        }

        let filtered = this.messages.filter((item) => { 
            let message_text = item.message.toLowerCase();
            return message_text.indexOf(this.text_filter.toLowerCase()) != -1; 
        });

        return filtered;
    }

  }, 
  mounted() {
  },
  sockets: {
        connect: function() {
            this.$forceUpdate();
        },
        log: function(data) {
            //console.log(data)
            this.messages.push(data);
            if(this.autoScroll) {
                this.$nextTick(() => {
                    $('#output').scrollTop($('#output')[0].scrollHeight);
                });
            }
        }
  }, 
  methods: { 
    clickRestart() {
      axios.post('https://signal-ct.cloud.ro/api/restart');
    },
    toggleAutoScroll() {
        this.autoScroll = !this.autoScroll;
    },
    toggleJson() {
        this.json_view = !this.json_view;
    }
  }
}
</script>