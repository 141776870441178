<template>
    <div class="tab devices" style="margin-top: 20px;">
        <v-row>
            <v-col col="8">
                <div class="card card-teal" data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Events</span>
                        <span></span>
                    </div>
                    <!-- <crud-table class="gen_table"  ></crud-table> -->
                </div>
            </v-col>
            <v-col cols='4'>
                <div class="lock_container">
                    <v-btn-toggle>
                        <span>
                            <v-btn :elevation="0" class="lock_btn button_teal float-left">Lock<br>Machine<v-img height='27' width='27' contain src='/img/lock.png'/></v-btn>
                        </span>
                        <span>
                            <v-btn :elevation="0" class="lock_btn button_teal float-left">Unlock<br>Machine<v-img height='27' width='27' contain src='/img/unlock.png'/></v-btn>
                        </span>
                    </v-btn-toggle>
                </div>
            </v-col>
        </v-row>                     
    </div>
</template>
<script>
import CrudTable from '@/components/tables/CrudTable.vue';
export default {
    components: {
        'crud-table':CrudTable,
    },
   
    data(){
        return { 
            data: null, 
            // fields:[

            //     {
            //         label: 'Serial No',
            //         getValue: item => item.device.serial,
            //         type: 'text',
            //         key: 'serial',
            //         ref: 'DeviceId',
            //         required: true
            //     },

            //     {
            //         label: 'Type',
            //         getValue: item => item.device.type.name, 
            //         type: 'text',
            //         key: 'typeId',
            //         ref: 'selectedDeviceType',
            //         required: true
            //     },

            //     {
            //         label: 'Manufacturer',
            //         getValue: item => (item.device.manufacturer) ? item.device.manufacturer.name : null,
            //         getEndpoint: item => 'deviceManufacturers', 
            //         type: 'text',
            //         key: 'manufacturerId',
            //     },

            //     {
            //         label:'Version',
            //         getValue: item => item.device.version,
            //         type:'text',
            //         key:'version'
            //     },
            //     {
            //         label: 'Protocol',
            //         getValue: item => item.device.protocol,
            //         type:'text',
            //         key:'protocol'
            //     },
            //     {
            //         label: 'API Key',
            //         getValue: item => item.device.apiKey,
            //         type:'text',
            //         key:'apiKey'
            //     },
            //     {
            //         label:'IP Address',
            //         getValue: item => item.device.ipAddress,
            //         type:'text',
            //         key:'ipAddress'
            //     },
            //     {
            //         label:'Port',
            //         getValue: item => item.device.port,
            //         type:'text',
            //         key:'port'
            //     },
            //     {
            //         label:'MAC Address',
            //         getValue: item => item.device.macAddress,
            //         type:'text',
            //         key:'macAddress'
            //     },
            // ],
        }
    },
    
    mounted(){

    },
    methods: {

    }
}
</script>