<template>
    <div class="page zone">
        <div class="header">
			<v-container>
                <v-row>
                    <v-col cols='6'>
                        <div class="entity" v-if='casinoZone' data-aos="fade">
                            <div class="title">{{casinoZone.name}}</div>
                            <div class="subtitle">{{casinoZone.type.name}} Zone</div>
                        </div>
                    </v-col>
                     <v-col cols="6">
                        <div class="header_button_container">
                            <v-btn @click='updateBoot' :elevation="0" class="print_btn float-right">Update Bootloader</v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="stats_container" v-if='statsData' data-aos="fade">
                    <v-col cols="12">
                        <v-slide-group show-arrows>
                            <v-slide-item v-for='(item,index) in cardInfo' :key='index'>
                                <v-card class="nav_tech_stats">
                                    <p>{{item.label}}</p>
                                    <div v-if='item.type == "col"'>
                                        <span class='value'>{{item.value[0]}}</span>	 
                                        <span class='value float-right'>{{item.value[1]}}</span>
                                    </div>
                                    <div v-if='!item.type'>
                                        <span class='value'>{{item.value}}</span>	 
                                    </div>
                                    <div v-if='item.type == "col"'>
                                        <span class='label float-left'>Connected</span>
                                        <span class='label float-right'>Total</span>
                                    </div>
                                    <div v-if='!item.type'>
                                        <span class='label float-left'>Connected</span>
                                    </div>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </v-col>
                </v-row>
			</v-container>
		</div>
        <div>
            <v-container>
                <v-row>
                    <v-col xs='12' sm='12' md='12' lg='12' xl='12'>
                        <div class="content">
                           <div class="tab egms">
                                <v-row>
                                    <v-col col="12">
                                        <div class="card card-teal" style='margin-top:60px'  v-if='data' data-aos="fade">
                                            <div class="card_header">
                                                <span class="float-left">EGMs</span>
                                                <span></span>
                                            </div>
                                            <crud-table class="gen_table" :data='data' :fields='fields' :canCheckbox='true' @selectAll='selectAll' @select='selectItem'></crud-table>
                                        </div>
                                    </v-col>
                                </v-row>                     
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="1000">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p>Update Bootloader</p>
                        <p>{{selectedItems.length}} EGM Selected </p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text>
                        <br>
                        <form>
                            <div class="fields">
                                <div class="field">
                                  <v-row>
                                      <v-col v-for='item in bootloaders.data' :key='item.id' cols='4'>
                                          <div  
                                            class='boot-card' 
                                            @click='selectBoot(item.id)'
                                            :class="{'selected':selectedBoot === item.id}"
                                            >
                                              <table>
                                                <tr v-for='(field,index) in bootFields' :key='index'>
                                                    <td class='label'>{{field.label}}</td>
                                                    <td class='value'>{{field.getValue(item)}}</td>
                                                </tr>
                                            </table>
                                          </div>
                                      </v-col>
                                  </v-row>
                                </div>  
                            </div>
                        </form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn"  :elevation="0" color="green" dark @click="createItem">
                           Upload
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div> 
    </div>
</template>
<script>

import CrudTable from '@/components/tables/CrudTable.vue'


export default {
    components:{
        'crud-table':CrudTable
    },
    data(){
        return {
            data:null,
            casino: null,
            casinoZone:null,
            statsData:null,
            bootloaders:null,
            editing:false,
            loading: false,
            fields:[
                {
                    label: 'Serie',
                    getValue: item => item.serial,
                    getLink: item => '/panel/technical/egm/' + item.id, 
                    type: 'text',
                    key: 'serial',
                },

                {
                    label: 'Manufacturer',
                    getValue: item => item.manufacturer.name,
                    type: 'text',
                    key: 'manufacturer'
                },

                {
                    label: 'Nr Aparat',
                    getValue: item => item.number, 
                    type: 'text',
                    key: 'number'
                },

                {
                    label:'Operator',
                    getValue: item => item.logisticStatus.name,
                    getClass: item=> (item.logisticStatus.name == 'Connected') ? 'isOn': 'isOff',
                    type:'text',
                    key:'logisticStatus'
                },
                {
                    label: 'Business',
                    getValue: item => (item.businessStatus.name==='LiveOnly') ? 'Live' : (item.businessStatus.name==='RemoteOnly') ? 'Remote' : 'Live&Remote',
                    type:'text',
                    key:'businessStatus'
                }, 
                {
                    label: 'Comm',
                    getValue: item => (item.streamingSystemOnline && item.controlBoardSystemOnline) ? 'Comm Ready' : 'Not available',
                    getClass: item=> (item.streamingSystemOnline &&  item.controlBoardSystemOnline) ? 'isOn': 'isOff',
                    type:'text',
                    key:'streamers'
                },
                {
                    label:'R2A',
                    getValue: item => item.signalStatus.toUpperCase(),
                    getClass: item => (item.signalStatus==='active') ? 'isOn' : (item.signalStatus==='offline') ? 'isOff' : 'inPlay',
                    type:'text',
                    key:'r2a'
                },
                { 
                    label:'RIB',
                    getValue: item => (item.controlBoard) ? item.controlBoard.signalTimestamp : 'Not available',
                    getClass: item => (item.controlBoard) ? ((item.controlBoard.signalStatus === 'online') ? 'isOn' : 'isOff') : 'isOff',
                    type:'date',
                    key:'rib'
                },
                {
                    label:'Game',
                    getValue: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.code === 'Game'); if(x[0]) {return x[0].signalTimestamp}else{return 'Not available'}},
                    getClass: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.code === 'Game'); if(x[0]) {return (x[0].signalStatus === "online") ? 'isOn' : 'isOff'}else{return 'isOff'}},
                    type:'date',
                    key:'game'
                }, 
                { 
                    label:'Jackpot',
                    getValue: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.code === 'Jackpot'); if(x[0]) {return x[0].signalTimestamp}else{return 'Not available'}},
                    getClass: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.code === 'Jackpot'); if(x[0]) {return (x[0].signalStatus) ? ((x[0].signalStatus === "online") ? 'isOn' : 'isOff') : 'isOff'}else{return 'isOff'}},
                    type:'date',
                    key:'jackpot'
                },
                {
                    label:'Floor',
                    getValue: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.id === 2); if(x[0]) {return x[0].signalTimestamp}else{return 'Not available'}},
                    getClass: (item) => {let x = item.videoStreams.filter(obj => obj.videoStreamType.id === 2); if(x[0]) {return (x[0].signalStatus) ? ((x[0].signalStatus === "online") ? 'isOn' : 'isOff') : 'isOff'}else{return 'isOff'}},
                    type:'date',
                    key:'floor'
                },
            ],
            selectedItems: [],
            selectedBoot:null
        }
    },
    computed:{
        cardInfo: function(){
            return [
            {
                label:'EGMs',
                value: [this.statsData.egms.active, this.statsData.egms.total],
                type: 'col'
            },
            {
                label:'Streamer',
                value: [this.statsData.allStreams.online, this.statsData.allStreams.total],
                type: 'col'
            },
            {
                label: 'RIB',
                value: [this.statsData.controlBoards.online, this.statsData.controlBoards.total],
                type: 'col'
            },
            {
                label: 'Service',
                value:  this.statsData.service.total,
                type:  null
            },
            {
                label: 'JP Streamer',
                value: [this.statsData.jpStreams.online, this.statsData.jpStreams.total],
                type:  'col'
            },
            {
                label: 'Surv Streamer',
                value: [this.statsData.survStreams.online, this.statsData.survStreams.total],
                type:  'col'
            },
            {
                label: 'Games',
                value: [this.statsData.gameStreams.online, this.statsData.gameStreams.total],
                type:  'col'
            },
        ]
        },
        bootFields: function(){
            return [
                {
                    label:'Version:',
                    getValue: item=>item.version
                },
                {
                    label:'Date:',
                    getValue: item=>Vue.moment(item.date).format("DD MMM YYYY")
                },
                {
                    label:'Target:',
                    getValue: item=>item.target
                },
                {
                    label:'Target Info:',
                    getValue: item=>item.targetInfo
                },
                {
                    label:'Size:',
                    getValue: item=>item.size
                },
            ]
        }
    },
    async mounted(){

        await this.getCasinoZone();
        await this.getCasino();


        this.getEgmsByZone();
        this.getZoneStats();
        this.getBootloaders();
    },
    sockets: {
        update_broadcasters: function() {
            this.getEgmsByZone();
            this.getZoneStats();
        }
    },
    methods: {

        async getCasinoZone(){

            try {
                let response = await axios.get('casinoZones/'+ this.$route.params.zone_id);
                this.casinoZone = response.data;
            } catch (error) {
                console.log(error);
            }

             /*axios.get('casinoZones/'+ this.$route.params.zone_id)
                .then(res=>{
                    this.casinoZone = res.data
                    console.log(this.casinoZone)
                })*/
        },

        async getCasino() {
            try {
                let response = await axios.get('system/casinos/' + this.casinoZone.casinoId);
                this.casino = response.data;
            } catch (error) {
                console.log(error);
            }
        },



        getEgmsByZone(){

            axios.get(this.casino.casinoLinks[0].url + '/api/zones/'+ this.$route.params.zone_id)
                .then(res=>{
                    this.data = res.data
                })
        },

        getZoneStats(){
            axios.get(this.casino.casinoLinks[0].url + '/api/zoneStats/'+ this.$route.params.zone_id)
                .then(res=>{
                    this.statsData = res.data
                })
        },
        getBootloaders(item){
            axios.get('infrastructure/bootloaders?pageIndex=0&pageSize=10000')
                .then(res=>{
                    this.bootloaders = res.data;
                })
        },
        selectAll(item){
            this.selectedItems = item;            
        },
        selectItem(item){
            this.selectedItems = item;     
        },
        selectBoot(item){
            this.selectedBoot = item;
        },
        updateBoot(){
            if(this.selectedItems.length<=0){
                Vue.toasted.error('There are no EGMs selected');
            } else {
                this.editing = true;
            }
            
        },
        createItem(){
            if(this.selectedBoot === null){
                Vue.toasted.error('There is no Bootloader selected');
                return
            }
            this.loading = true;  
            let payload = {
                egmId:this.selectedItems,
                bootloaderId:this.selectedBoot
            }
            console.log(payload);
        //   axios.post('bootloaderFiles', formData)
        //       .then(res=> {
        //           Vue.toasted.success('Item created.');
        //           this.getBootloaders();
        //           if(!keepOpen){
        //               this.closeModals();
        //           }

        //           this.loading = false;
        //       })
        //       .catch(err => {

        //           if(err.response.data.title) {
        //               Vue.toasted.error(err.response.data.title); 
        //           } else {
        //               Vue.toasted.error('There was an error processing your request'); 
        //           }

        //           this.loading = false;

        //         });
        },
        closeModals() {
            this.editing = false;
            this.selectedBoot = null;
        },
       
    }
}
</script>