<template>
    <ul>
        <li v-for="(item,index) in switchNames" :key="index" v-ripple @click="changeTab(index)" v-bind:class="{tab_active:isActive === item.value}">{{item.label}}</li>
    </ul>   
</template>
<script>
export default {
    props:['switchNames'],
    data() {
        return {
            isActive:''
        }
    },
    mounted(){ 
        this.isActive = this.switchNames[0].value       
    },
    methods: {
        changeTab(){
            this.$emit('changeActive')
        }
    }
}
</script>