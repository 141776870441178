<template>
    <div class="page casino">
        <div class="header">
            <v-container>
                <v-row>
                    <v-col cols='6'>
                        <div class="entity" v-if='data' data-aos="fade">
                            <div class="title">{{data.type.code}} {{data.serial}}</div>
                            <div class="subtitle">{{data.manufacturer.name}}</div>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="stats_container" v-if='status' data-aos="fade">
                    <v-col cols="12">
                        <v-slide-group show-arrows>
                            <v-slide-item>
                                <v-card v-for='(item,index) in deviceStatus' :key='index' class="nav_tech_stats_color" :class='item.class'>
                                    <p>Operator</p>
                                    <div>
                                        <span class='value'>{{item.status}}</span>
                                    </div>
                                    <div class>
                                        <span class='label'>{{item.value | moment("from", 'now' , true )}}</span>
                                    </div>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div>
            <v-container>
                <v-row>
                    <v-col xs='12' sm='12' md='3' lg='3' xl='3' class="side_bar">
                        <div class="navigation navigation-blue">
                            <tab-switch :switchNames="switchNames" @changeActive='changeActiveSideTab'></tab-switch>
                        </div>
                        <div class="card card-blue" v-if="switchNames[0].value && data" data-aos="fade" style='margin-top:20px'>
                            <div class="card_header sys_info">
                                <span>Status</span>
                            </div>
                            <span class="vertical_table_header">Identification</span>
                            <div class="table_container">
                                <vertical-table :sysInfo='identificationFields'></vertical-table>
                            </div>
                            <span class='info_card_divider'></span>
                            <span class="vertical_table_header">Network</span>
                            <div class="table_container">
                                <vertical-table :sysInfo='networkFields'></vertical-table>
                            </div>
                            <span class='info_card_divider'></span>
                            <span class="vertical_table_header">Operational Settings</span>
                            <div class="table_container">
                                <vertical-table :sysInfo='opsFields'></vertical-table>
                            </div>
                        </div>
                    </v-col>
                    <v-col xs='12' sm='12' md='9' lg='9' xl='9'>
                        <div class="navigation navigation-teal">
                            <tab-nav :tabNames="tabNames"></tab-nav>
                        </div>
                        <div class="content">
                            <router-view></router-view>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
import TabNav from '@/components/navigation/TabNav.vue'
import TabSwitch from '@/components/navigation/TabSwitch.vue'
import CrudTable from '@/components/tables/CrudTable.vue'
import VerticalTable from '@/components/tables/VerticalTable.vue'



export default {
    components: {
        'tab-nav': TabNav,
        'tab-switch': TabSwitch,
        'crud-table': CrudTable,
        'vertical-table': VerticalTable
    },
    data() {

        return {
            casino: null,
            data: null,
            status: null,
            cardInfo: [{
                    label: 'Games',
                    value: null
                },
                {
                    label: 'Zones',
                    value: null,
                },
                {
                    label: 'Devices',
                    value: null
                }
            ],
            tabNames: [{
                    label: 'Data Log',
                    route: 'dataLog'
                },
                {
                    label: 'Events/Alarms',
                    route: 'event'
                },
                {
                    label: 'Maintenance Log',
                    route: 'maintenanceLog'
                }
            ],
            switchNames: [{
                label: 'EGM Info Card',
                value: true
            }],

        }
    },
    computed: {
        identificationFields: function() {
            return [{
                    label: 'Manufacturer:',
                    value: this.data.manufacturer.name
                },
                {
                    label: 'Model:',
                    value: this.data.type.code
                },
                {
                    label: 'Version:',
                    value: this.data.version
                },
                {
                    label: 'Serial No:',
                    value: this.data.serial
                },
                {
                    label: 'Internal Code:',
                    value: null
                }
            ]
        },
        networkFields: function() {
            return [{
                    label: 'IP:',
                    value: this.data.ipAddress
                },
                {
                    label: 'Port:',
                    value: this.data.port
                },
                {
                    label: 'MAC:',
                    value: this.data.macAddress
                }
            ]
        },
        opsFields: function() {
            return [{
                    label: 'Audit:',
                    value: null
                },
                {
                    label: 'Status:',
                    value: null
                },
                {
                    label: 'Min Credit Interval:',
                    value: null
                }
            ]
        },
        deviceStatus: function() {
            return [{
                status: this.status.signalStatus ? (this.status.signalStatus === 'online' ? 'Connected' : 'Offline') : 'Offline',
                value: this.status.signalTimestamp ? this.status.signalTimestamp : null,
                class: this.status.signalStatus ? (this.status.signalStatus === 'online' ? 'isLive' : 'isOffline') : 'isOffline'
            }]
        }
    },
    async mounted() {
        await this.getDevice();
        await this.getCasino();
        this.getDeviceStatus();
    },
    methods: {
        async getDevice() {
            let res = await axios.get('devices/' + this.$route.params.device_id);
            this.data = res.data;
        },
        async getCasino() {
            let res = await axios.get('system/casinos/' + this.data.casinoId);
            this.casino = res.data;
        },
        getDeviceStatus() {
            console.log(this.casino)
            axios.get(this.casino.casinoLinks[0].link + '/api/devices/' + this.$route.params.device_id)
                .then(res => {
                    this.status = res.data;
                })
        },
        changeActiveSideTab() {
            this.switchNames.forEach(el => {
                el.value = !el.value;
            });
        }

    }
}
</script>