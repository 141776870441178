<template>
    <div class="tab virtual" style="margin-top: 20px;">
        <v-row>
            <v-col col="12">
                <div class="card card-teal" v-if='data' data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Zones</span>
                        <span></span>
                    </div>
                    <crud-table class="gen_table"  :data='data' :fields='fields'></crud-table>
                </div>
            </v-col>
        </v-row>                     
    </div>
</template>
<script>
import CrudTable from '@/components/tables/CrudTable.vue';
export default {
    components: {
        'crud-table':CrudTable,
    },
    props:['zoneTypes', 'casino'],
    data(){
        return { 
            data: null, 
            zoneId:null,
            fields:[

                {
                    label: 'Location/Zone',
                    getValue: item => item.zone.name,
                    getLink: item => '/panel/technical/zone/' + item.zone.id, 
                    type: 'text',
                    key: 'name'
                },

                {
                    label: 'Zone Score',
                    getValue: item => item.zone.score, 
                    type: 'text',
                    key: 'score'
                },

                {
                    label: 'Status',
                    getValue: item => item.zone.signalStatus,
                    getClass: item=>(item.zone.signalStatus=='online')? 'isOn': 'isOff',
                    type: 'text',
                    key: 'status',
                },

                {
                    label:'Connected',
                    getValue: item => item.connected,
                    type:'text',
                    key:'connected'
                },
                {
                    label: 'Comm NR',
                    getValue: item => item.commNr,
                    type:'text',
                    key:'commNr'
                },
                {
                    label: 'Streamer',
                    getValue: item => item.zone.allStreams.online + '/' + item.zone.allStreams.total,
                    type:'text',
                    key:'streamers'
                }, 
                {
                    label:'RIB',
                    getValue: item => item.zone.controlBoards.online + '/' + item.zone.controlBoards.total,
                    type:'text',
                    key:'rib'
                },
                {
                    label:'Service',
                    getValue: item => item.service,
                    type:'text',
                    key:'service'
                },
                {
                    label:'JP Streamer',
                    getValue: item => item.zone.jpStreams.online + '/' + item.zone.jpStreams.total,
                    type:'text',
                    key:'jpStreamers'
                },
                { 
                    label:'Surv Streamer',
                    getValue: item => item.zone.survStreams.online + '/' + item.zone.survStreams.total,
                    type:'text',
                    key:'survStreamers'
                },
            ],
        }
    },
    
    mounted(){
        this.zoneTypes.forEach(el => {
            if(this.$route.name.includes(el.name)){
                this.zoneId = el.id;
            }
        });
        this.getCasinoZone();
    },
    sockets: {
        update_broadcasters: function() {
            this.getCasinoZone();
        }
    },
    methods: {
        getCasinoZone(){
             axios.get(this.casino.casinoLinks[0].url + '/api/zoneTypes/'+ this.zoneId)
                .then(res=>{
                    this.data = res.data;             
                });
        }
    }
}
</script>