<template>
    <div class="view-stream">

        <!-- Controls -->
        <div class="row">
            <div class="col-12">
               
                <div class="form-group connected" v-if="selected_broadcaster !== 'null'">
                    <span style="text-transform: capitalize;" :class="(peer_connection_state === 'connected') ? 'text-success' : 'text-danger'">
                        <i class="fas fa-circle dot" style="font-size: 12px;"></i>
                        {{ peer_connection_state }}
                    </span>
                </div>
            </div>
        </div>
 
        <!-- Video Area -->
        <div class="row">
            <div class="col-12">
                <div class="video">
                    <video style="width: 100%" :id="'video_' + code" ref="video" class="videoStream" playsinline autoplay muted></video>
                </div>
            </div>
        </div>

    
        <!-- Debug Area -->
        <div class="container section debug" v-if="debug">
            <!-- Stats -->
            <div class="row" v-if="peer_connection">
                <div class="col-12">
                </div>
            </div>
        </div>
        
            <div class="row" v-if="peer_connection">
                <div class="col-12">
                	 <div class="form-group">
                    <div class="form-check">
                        <input v-model="force_turn" type="checkbox" class="form-check-input" id="checkTurn">
                        <label class="form-check-label" > &nbsp; Only accept TURN connections</label>
                    </div>
	                </div>

                    <div class="form-check">
                        <input v-model="debug" type="checkbox" class="form-check-input" id="checkStats">
                        <label class="form-check-label"> &nbsp; Display stream statistics</label>
                    </div>
                </div>
            </div>
        
    </div>
</template>
<script>
export default {
	props: ['code'],
    data() {
        return {
            debug: false,
            force_turn: false,

            video_div: null,

            // Socket.io
            socket: null,
            socket_address: '',
            socket_query: {
                client_type: 'watcher',
            },

            // Control Boards
            smib_id: 1,
            axios_config: {
                headers: {
                    AuthKey: 'a6abc6c14df6cf31e09c2728549529a745a47436',
                    ClientId: 'CASINO2.0',
                    'Content-Type': 'application/json'
                }
            },
            stats: {

            },



            // Streams
            selected_broadcaster: 'null',
            broadcasters: [],

            // WebRTC Broadcaster Connection
            peer_connection_state: 'disconnected',
            peer_connection_stats: {
                video: [],
                audio: [],
            },
            peer_connection: null,
            // WebRTC Configuration
            webrtc_config: {
                iceServers: [

                    //{ urls: 'stun:stun.l.google.com:19302', },
                    //{ urls: 'stun:stun1.l.google.com:19302' },
                    //{ urls: 'stun:stun2.l.google.com:19302' },
                    //{ urls: 'stun:stun3.l.google.com:19302' },
                    //{ urls: 'stun:stun4.l.google.com:19302' },

                    { urls: 'stun:stun.cloud.ro:5349' },
                    {
                        urls: 'turn:turn.cloud.ro:5349',
                        username: 'turnuser',
                        credential: 'letmein123',
                    }

                    // { 
                    //   "urls": "turn:TURN_IP?transport=tcp",
                    //   "username": "TURN_USERNAME",
                    //   "credential": "TURN_CREDENTIALS"
                    // }
                ]
            }
        }
    },
    mounted() {

        var self = this;

        self.video_div = document.getElementById("video_" + this.code);

        // Initiate web rtc connection
        self.socket = this.$socket;

        var self = this;
        setInterval(function() {
            self.getStats();
        }, 1000);

        self.getBroadcasters();

    },

    destroyed() {

        console.log('desrtoyed');
        this.closeConnection(this.selected_broadcaster);

    },

    sockets: {

    	offer: function(data) {

    		let	broadcaster_socket_id = data[0];
    		let description = data[1];

    		if(broadcaster_socket_id !== this.selected_broadcaster) {
    			return;
    		}
    		
    		this.answerOffer(broadcaster_socket_id, description);

    	},
    	candidate: function(data) {

    		let	broadcaster_socket_id = data[0];
    		let candidate = data[1];

    		if(broadcaster_socket_id !== this.selected_broadcaster) {
    			return;
    		}


    		if (this.force_turn) {
                var stringCandidate = candidate.candidate;
                if (stringCandidate.indexOf("relay") < 0) {
                    return;
                }
            }

            this.peer_connection.addIceCandidate(new RTCIceCandidate(candidate));

    	},
    	update_broadcasters: function(data) {

    		let state = data[0];
    		let broadcaster_socket_id = data[1];

    		if (state === 'disconnected') {
                if (this.selected_broadcaster === broadcaster_socket_id) {

                    this.closeConnection();
                    this.selected_broadcaster = 'null';

                }
            }

            this.getBroadcasters();


    	}




    },

    watch: {

        force_turn: function(oldValue, newValue) {

            this.closeConnection(this.selected_broadcaster);
            this.reconnect();

        },

        // On stream selection change
        selected_broadcaster: function(newStream, oldStream) {

            // Close any existing peer connection
            this.closeConnection(oldStream);

            // Return if selected none
            if (this.selected_broadcaster === 'null') {
                this.$forceUpdate();
                return;
            }

            // Broadcast the intetion to watch to the
            // specific stream
            this.socket.emit('watch', this.selected_broadcaster);
            this.$forceUpdate();

        }

    },

    methods: {

        addCommas(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },


        getStats() {

            if (this.peer_connection) {

                var broadcaster = this.broadcasters.find(item => item.id === this.selected_broadcaster);
                var smib_id = broadcaster.query.smib_id;

                axios.post('https://old.cloud.ro/api/1.0/unigens/ed_ioboard_get_meters.php?smib_id=' + smib_id, {}, this.axios_config)
                    .then((res) => {



                        if (res.data.result) {
                            this.stats = res.data.result;
                        } else {
                            this.stats = {};
                        }


                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }

        },

        sendCommand(command) {

            /*data = {
              button_key: command,
            }*/

            var broadcaster = this.broadcasters.find(item => item.id === this.selected_broadcaster);
            var smib_id = broadcaster.query.smib_id;

            var data = new FormData();
            data.append('button_key', command);



            axios.post('https://old.cloud.ro/api/1.0/unigens/ed_ioboard_set.php?smib_id=' + smib_id, data, this.axios_config)
                .then((res) => {
                    console.log(res.data);
                })
                .catch((error) => {
                    console.log(error);
                });

            console.log(this.smib_id);
            console.log('Command issued: ' + command);
        },

        closeConnection(closedBroadcasterId) {

            if (this.peer_connection) {

                this.socket.emit('stop_watch', closedBroadcasterId);

                this.peer_connection.close();
                this.peer_connection = null;
                this.peer_connection_state = 'disconnected';
                this.video_div.srcObject = null;
                this.$forceUpdate();
            }


        },

        reconnect() {

            if (this.selected_broadcaster) {
                if (this.selected_broadcaster !== 'null') {
                    console.log('Connection lost, attempting to reconnect...');
                    this.socket.emit('watch', this.selected_broadcaster);
                }
            }

        },

        answerOffer: function(broadcaster_socket_id, description) {

            var self = this;

            // Initiate a new WebRTC Peer Connection based on our 
            // config file and the description sent from the broadcaster
            self.peer_connection = new RTCPeerConnection(this.webrtc_config);
            self.peer_connection
                .setRemoteDescription(description)
                .then(() => self.peer_connection.createAnswer())
                .then(sdp => self.peer_connection.setLocalDescription(sdp))
                .then(() => {
                    self.socket.emit("answer", broadcaster_socket_id, self.peer_connection.localDescription);
                });

            // On receive track from broadcaster
            self.peer_connection.ontrack = event => {
                self.video_div.srcObject = event.streams[0];
                self.$forceUpdate();
            };

            // Emit our watcher candidates to 
            // the broadcaster
            self.peer_connection.onicecandidate = event => {
                if (event.candidate) {


                    if (self.force_turn) {
                        // Only accept turn connections
                        var candidate = event.candidate.candidate;
                        if (candidate.indexOf("relay") < 0) {
                            return;
                        }
                    }


                    self.socket.emit("candidate", broadcaster_socket_id, event.candidate);
                }
            };

            self.peer_connection.onconnectionstatechange = function() {

                self.peer_connection_state = self.peer_connection.connectionState;
                self.$forceUpdate

                // Handle peer disconnect
                if (self.peer_connection_state === 'disconnected') {
                    self.reconnect();
                }

                console.log('state changed to:' + self.peer_connection.connectionState);
            }




        },


        getBroadcasters: function() {

            var self = this;

            axios.get('https://signal-ct.cloud.ro/api/streams')
                .then(function(res) {

                    self.broadcasters = res.data;

                    let broadcaster = self.broadcasters.find(item => item.query.stream_key === self.code); 

					if(broadcaster) {
                    	self.selected_broadcaster = broadcaster.id;
                    }

                    //console.log(self.broadcasters);


                })
                .catch(function(error) {
                    console.log(error);
                });
        },


        handleError: function(error) {
            console.log(error);
        }

    }
}
</script>