<template>
    <div class="tab previews" style="margin-top: 20px;">
        <v-row  >
            <v-col v-for="stream in egmData.videoStreams" :key="stream.id"  :cols="(stream.expanded) ? '12' : '4'">
                <div class="card card-teal" data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">{{ stream.videoStreamType.name }} Stream</span>
                        <span class="float-right pointer" @click="expand(stream)"><v-icon>{{(stream.expanded) ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'}}</v-icon></span>
                        <br>
                        <span>{{stream.code}}</span><br>
                    </div>
                    
                	<div class="stream"><br>
                		<view-stream :code="stream.code" />                		
                	</div>
                    
                 
                    
                </div>
            </v-col>
        </v-row>                     
    </div>
</template>
<script>
import ViewStream from '@/components/video/ViewStream.vue';
export default {
	props: ['egmData'],
    components: {
        'view-stream': ViewStream,
    },
    data(){
        return { 
            data: null, 
        }
    },
    mounted(){
    },
    methods: {

        expand(stream) {

            stream.expanded = !stream.expanded;
            this.$forceUpdate();
        }

    }
}
</script>