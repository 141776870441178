<template>
    <div class="page casino">
        <div class="header">
			<v-container v-if='data' data-aos="fade">
                <v-row>
                    <v-col cols='6'>
                        <div class="entity" >
                            <div class="title">{{data.serial}}</div>
                            <div class="subtitle">{{data.manufacturer.name}}</div>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="header_button_container">
                            <v-btn @click='remoteSwitch' :elevation="0" class="print_btn float-right">{{(this.data.businessStatus.code==='RemoteOnly') ? 'Disable Remote' : 'Activate Remote'}}</v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="stats_container">
                    <v-col cols="12">
                        <v-slide-group 
                        >
                            <v-slide-item v-for='(item,index) in cardInfo' :key='index'>
                                <v-card class="nav_tech_stats_color" :class='item.class'>
                                    <p>{{item.label}}</p>
                                    <div v-if='!item.type'>
                                        <span class='value'>{{item.status}}</span>	 
                                    </div>
                                    <div v-if='item.type'>
                                        <span class='value'>{{item.status | moment('hh:mm:ss')}}</span>	 
                                    </div>
                                    <div v-if='item.value'>
                                        <span class='label'>{{item.value |  moment("from", 'now' , true )}}</span>
                                    </div>  
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                        
                    </v-col>
                    
                </v-row>
			</v-container>
		</div>
        <div>
            <v-container>
                <v-row>
                    <v-col xs='12' sm='12' md='3' lg='3' xl='3' class="side_bar">
                        <div class="navigation navigation-blue">
                            <tab-switch :switchNames="switchNames" @changeActive='changeActiveSideTab'></tab-switch>
                        </div>
                        
                        <div class="card card-blue" v-if="switchNames[0].value" data-aos="fade" style='margin-top:20px'>                            
                            <div class="card_header sys_info">
                                <span>Status</span>
                            </div>
                            <div class="table_container">
                               <vertical-table v-if='egmInfo' :sysInfo='infoFields'></vertical-table>
                            </div>
                        </div>
                    </v-col>

                    <v-col xs='12' sm='12' md='9' lg='9' xl='9'>
                        <div class="navigation navigation-teal">
                            <tab-nav :tabNames="tabNames"></tab-nav>
                        </div>
                        <div class="content">
                            <router-view v-if='data' :egmData='data'></router-view>     
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
import TabNav from '@/components/navigation/TabNav.vue'
import TabSwitch from '@/components/navigation/TabSwitch.vue'
import CrudTable from '@/components/tables/CrudTable.vue'
import VerticalTable from '@/components/tables/VerticalTable.vue'



export default {
    components:{
        'tab-nav':TabNav,
        'tab-switch':TabSwitch,
        'crud-table':CrudTable,
        'vertical-table':VerticalTable
    },
    data(){
  
        return {
            data:null,
            egmInfo:null,
            casino: null,
            tabNames: [
                {
                    label:'Devices',
                    route:'device'
                },
                {
                    label:'Log',
                    route:'log'
                },
                {
                    label:'Previews',
                    route:'preview'
                },
                {
                    label:'Events',
                    route:'event'
                },
                {
                    label:'Maintenace Log',
                    route:'maintenance'
                }
            ],
            switchNames: [
                {
                    label:'EGM Info Card',
                    value: true
                }
            ],

        }
    },
    computed:{
        infoFields: function(){
            return [
                {
                    label:'Machine No:',
                    value: this.egmInfo.number
                },
                {
                    label:'Cabinet:',
                    value: null
                },
                 {
                    label:'Game Mix:',
                    value: this.egmInfo.gameMixId
                },
                {
                    label:'Machine RTP:',
                    value: this.egmInfo.rtp
                },
                {
                    label:'Denomination:',
                    value: this.egmInfo.denominationInMoney
                },
                {
                    label:'EEPROM Version:',
                    value: this.egmInfo.eepromVersion
                },
                {
                    label:'Game Mix Volatility:',
                    value:null
                },
                {
                    label:'Checksum:',
                    value: this.egmInfo.checksum
                },
                {
                    label:'Keyboard Layout:',
                    value: this.egmInfo.model.egmButtonLoayoutId
                },
            ]
        },
        cardInfo: function(){
            let game = this.data.videoStreams.filter(obj=>obj.videoStreamType.id === 1);
            let jackpot = this.data.videoStreams.filter(obj=>obj.videoStreamType.id === 3);
            let floor = this.data.videoStreams.filter(obj=>obj.videoStreamType.id === 2);
            return [
            {
                label:'Operator',
                status: this.data.logisticStatus.code,
                value: null,
                class: (this.data.logisticStatus.code.toLowerCase() === 'connected') ? 'isLive' : 'isOffline',
                type:null
            },
            {
                label:'Business',
                status: (this.data.businessStatus.code === 'LiveOnly') ? 'Live Only' : (this.data.businessStatus.code === 'RemoteOnly') ? 'Remote Only' : 'Live & Remote',
                value: null,
                class: (this.data.businessStatus.code==='RemoteOnly') ? 'isLive' : (this.data.businessStatus.code==='LiveOnly') ? 'isOffline' : 'notAvailable',
                type:null
            },
            {
                label: 'Comm',
                status: (this.data.streamingSystemOnline && this.data.controlBoardSystemOnline) ? 'Comm Ready' : 'Not available',
                value: null,
                class: (this.data.streamingSystemOnline && this.data.controlBoardSystemOnline) ? 'isLive' : 'isOffline',
                type:null
            },
            {
                label: 'R2A',
                status: this.data.signalStatus.toUpperCase(),
                value: this.data.signalStatusChange,
                class: (this.data.signalStatus==='active') ? 'isLive' : (this.data.signalStatus==='offline') ? 'isOffline' : 'isPlay',
                type:null
            },
            {
                label: 'RIB',
                status: (this.data.controlBoard) ? (this.data.controlBoard.signalStatus.charAt(0).toUpperCase() + this.data.controlBoard.signalStatus.slice(1)) : 'Not Available',
                value:  (this.data.controlBoard) ? this.data.controlBoard.signalTimestamp : null,
                class: (this.data.controlBoard) ? ((this.data.controlBoard.signalStatus === 'online') ? 'isLive' : 'isOffline') : 'notAvailable',
                type:null
            },
            {
                label: 'Game',
                status: (game[0]) ? game[0].signalTimestamp : 'None',
                value:  null,
                class: (game[0]) ? (game[0].signalStatus === 'online')?'isLive':'isOffline' : 'isOffline',
                type: 'game'
            },
            {
                label: 'Jackpot',
                status: (jackpot[0]) ? (jackpot[0].signalStatus === 'offline')?'Not Available': 'Available' : 'None',
                value: null,
                class: (jackpot[0]) ? ((jackpot[0].signalStatus === 'offline')?'notAvailable': 'isLive') : 'notAvailable',
                type:null
            },
            {
                label: 'Floor',
                status: (floor[0]) ? (floor[0].signalStatus === 'offline') ? 'Not Available': 'Available' : 'None',
                value: null,
                class: (floor[0]) ? ((floor[0].signalStatus === 'offline') ? 'notAvailable': 'isLive') : 'notAvailable',
                type:null
            }
        ]
        },
        businessStatus:function(){
            return (this.data.businessStatus.code==='RemoteOnly') ? 'LiveOnly' : 'RemoteOnly'
        }
    },
    async mounted(){
        await this.getEgmInfo();
        await this.getCasino();
        this.getEgm();        
    },
    sockets: {
        update_broadcasters: function() {
            this.getEgm();
        }
    },
    methods: { 
        getEgm(){
             axios.get(this.casino.casinoLinks[0].url + '/api/machines/' + this.$route.params.egm_id)
                .then(res=>{
                    this.data = res.data;     
                    // this.egmGame = this.data.videoStreams.filter(obj=>obj.videoStreamType.code == 'Game');  
                    // this.egmJackpot = this.data.videoStreams.filter(obj=>obj.videoStreamType.code == 'Jackpot');   
                });
        },
        async getCasino() {
            try {
                let res = await axios.get('/system/casinos/' + this.egmInfo.casinoId);
                this.casino = res.data;
                console.log(this.casino)
            } catch (error) {
                console.log(this.error);
            }
        },
        async getEgmInfo(){
            try {
                let res = await axios.get('egMachines/'+ this.$route.params.egm_id);
                this.egmInfo = res.data;
            } catch (error) {
                console.log(error);
            }
        },
        changeActiveSideTab(){
            this.switchNames.forEach(el=>{
                el.value = !el.value;
            });
        },
        remoteSwitch(){
            axios.post(this.casino.casinoLinks[0].url + '/api/machines/' + this.$route.params.egm_id + '/setBusinessStatus', {businessStatus: this.businessStatus})
                .then(res=>{

                })
                .catch(err => {
                if(err.response.data.title) {
                    Vue.toasted.error(err.response.data.title); 
                } else {
                    Vue.toasted.error('There was an error processing your request'); 
                }
            });
                
        }
    }
}
</script>